import { get, post } from '@/utils/request';
// 获取列表
const getList = () => {
  return post({
    url: '/admin/purview.Node/index',
  })
};

// 新增权限
const createAuthority = ({ id, pid, name, path, title, is_menu, access_type, sort, status }) => {
  return post({
    url: '/admin/purview.Node/create',
    data: {
      id,
      pid,
      name,
      path,
      title,
      is_menu,
      access_type,
      sort,
      status,
    }
  })
};

// 删除权限
const deleteAuthority = (id) => {
  return get({
    url: '/admin/purview.Node/delete',
    params: {
      id,
    }
  })
}

// 快捷修改节点状态
const handstatus = ({id, type, field, status}) => {
  return post({
    url: '/admin/purview.Node/handstatus',
    data: {
      id,
      type,
      field,
      status,
    }
  })
}


// 编辑权限
const eidtAuthority = ({ id, pid, name, title, is_menu, access_type, sort, status, path }) => {
  return post({
    url: '/admin/purview.Node/edit',
    data: {
      id,
      pid,
      name,
      title,
      is_menu,
      access_type,
      sort,
      status,
      path,
    }
  })
};

export {
  getList,
  createAuthority,
  deleteAuthority,
  handstatus,
  eidtAuthority,
};
