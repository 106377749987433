// 私有变量
const __access_type__ = {
  10: '普通权限',
  20: '代理商级别权限',
  30: '后台级别权限',
};

export {
  __access_type__,
};
