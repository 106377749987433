<template>
  <div>
    <div class="card">
      <el-button type="primary" @click="handleAdd(0)" icon="el-icon-plus" :loading="loading">添加</el-button>
    </div>
    <div class="table">
      <el-table
        :data="listData"
        v-loading="loading"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        stripe
        border
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
          prop="title"
          label="权限名称">
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="后端路径">
        </el-table-column>
        <el-table-column
          prop="path"
          align="center"
          label="前端路由">
        </el-table-column>
        <el-table-column
          prop="level"
          align="center"
          label="权限级别">
          <template slot-scope="scope">
            {{access_type[scope.row.access_type]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="isMenu"
          align="center"
          label="是否菜单">
            <template slot-scope="scope">
              <el-switch
                style="display: block"
                v-model="scope.row.is_menu"
                :disabled="scope.row.level === 1 || !scope.row.level"
                @change="changeSwitch($event, scope.row, 2)"
              >
              </el-switch>
            </template>
        </el-table-column>
        <el-table-column
          prop="isUse"
          align="center"
          label="是否启用">
            <template slot-scope="scope">
              <el-switch
                style="display: block"
                v-model="scope.row.status"
                :disabled="scope.row.level === 1"
                @change="changeSwitch($event, scope.row, 1)"
              >
              </el-switch>
            </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          align="center"
          label="权限排序">
        </el-table-column>
        <!-- <el-table-column
          prop="creataTime"
          align="center"
          label="添加时间">
        </el-table-column> -->
        <el-table-column
          align="center"
          fixed="right"
          width="180"
          label="操作">
            <template slot-scope="scope">
              <el-select v-model="scope.row.select" placeholder="请选择" @change="handleChange($event, scope.row)" >
                <el-option
                  label="添加下级权限"
                  :value="0"
                  v-if="scope.row.level"
                >
                </el-option>
                <el-option
                  label="编辑"
                  v-if="scope.row.level !== 1"
                  :value="1">
                </el-option>
                <el-option
                  label="删除"
                  v-if="scope.row.level !== 1"
                  :value="2">
                </el-option>
              </el-select>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="type === 0? '添加顶级权限': '添加下级权限'" :visible.sync="dialogFormVisible" width="30%" :destroy-on-close="true" :before-close="handleCancle">
      <el-form :model="form">
        <el-form-item label="权限名称" :label-width="formLabelWidth" required>
          <el-input v-model="form.title" autocomplete="off" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="后端路径" :label-width="formLabelWidth" required>
          <el-input v-model="form.name" autocomplete="off" placeholder="例如/index/create"></el-input>
        </el-form-item>
        <el-form-item label="前端路由" :label-width="formLabelWidth" required v-show="current.level !== 4">
          <el-input v-model="form.path" autocomplete="off" placeholder="例如/index/create"></el-input>
        </el-form-item>
        <el-form-item label="权限排序" :label-width="formLabelWidth" required>
          <el-input v-model="form.sort" autocomplete="off" placeholder="请输入整数"></el-input>
        </el-form-item>
        <el-form-item label="权限级别" :label-width="formLabelWidth" required>
          <el-select v-model="form.access_type" placeholder="请选择">
            <template v-for="(accessValue, accessKey) in access_type">
              <el-option :label="accessValue" :value="accessKey" :key="accessKey"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="是否菜单" :label-width="formLabelWidth" required v-show="current.level !== 4">
          <el-switch v-model="form.is_menu"></el-switch>
        </el-form-item>
        <el-form-item label="是否启用" :label-width="formLabelWidth" required>
          <el-switch v-model="form.status"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑权限" :visible.sync="editVisible" width="30%" :destroy-on-close="true" :before-close="handleCancle">
      <el-form :model="currentEidt">
        <el-form-item label="权限名称" :label-width="formLabelWidth" required>
          <el-input v-model="currentEidt.title" autocomplete="off" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="后端路径" :label-width="formLabelWidth" required>
          <el-input v-model="currentEidt.name" autocomplete="off" placeholder="例如/index/create"></el-input>
        </el-form-item>
        <el-form-item label="前端路由" :label-width="formLabelWidth" required v-show="current.level">
          <el-input v-model="currentEidt.path" autocomplete="off" placeholder="例如/index/create"></el-input>
        </el-form-item>
        <el-form-item label="权限排序" :label-width="formLabelWidth" required>
          <el-input v-model="currentEidt.sort" autocomplete="off" placeholder="请输入整数"></el-input>
        </el-form-item>
        <el-form-item label="权限级别" :label-width="formLabelWidth" required>
          <el-select v-model="currentEidt.access_type" placeholder="请选择">
            <template v-for="(accessValue, accessKey) in access_type">
              <el-option :label="accessValue" :value="accessKey" :key="accessKey"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="是否菜单" :label-width="formLabelWidth" required v-show="current.level">
          <el-switch v-model="currentEidt.is_menu"></el-switch>
        </el-form-item>
        <el-form-item label="是否启用" :label-width="formLabelWidth" required>
          <el-switch v-model="currentEidt.status"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleEdit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="deleteVisible" width="30%" :destroy-on-close="true" :before-close="handleCancle">
      <span>确定要删除该节点吗?</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleDele">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList, createAuthority, deleteAuthority, handstatus, eidtAuthority } from '@/api/system/authorityList';
import { __access_type__ } from '@/utils/cache.js';
export default {
  name: 'AuthorityList',
  data() {
    return {
      editVisible: false,
      currentEidt: {}, // 当前要编辑的对象
      deleteVisible: false,
      current: {}, // 当前要添加下级的对象
      access_type: {},
      loading: false,
      type: 0,
      form: {},
      formLabelWidth: '90px',
      dialogFormVisible: false,
      listData: [],
    };
  },
  activated() {
    // console.log(this.$vnode.parent.componentInstance.cache);
  },
  methods: {
    handleCancle() {
      this.dialogFormVisible = false;
      this.editVisible = false;
      this.deleteVisible = false;
      this.form = {};
      this.current.select = null;
    },
    changeSwitch(e, row, type) {
      let field;
      if (type === 1) {
        field = 'status'
        if (e) {
          status = 1
        } else {
          status = 0
        }
      } else {
        field = 'is_menu'
        if (e) {
          status = 20
        } else {
          status = 10
        }
      }
      handstatus({
        id: row.id,
        type,
        field,
        status,
      })
        .then(res => {
          if (res.code === 1) {
            // this.getList();
          }
        })
    },
    handleData(arr) {
      arr.forEach(item => {
        if (item.is_menu === 10) {
          item.is_menu = false
        } else {
          item.is_menu = true
        }
        if (item.status === 0) {
          item.status = false
        } else {
          item.status = true
        }
        item.children && this.handleData(item.children);
      })
    },
    handleEdit() {
      let form = {...this.currentEidt};
      // 整理数据为后端要求格式
      if (form.is_menu) {
        form.is_menu = 20
      } else {
        form.is_menu = 10
      }
      if (form.status) {
        form.status = 1
      } else {
        form.status = 0
      }
      form.access_type = Number(form.access_type);
      eidtAuthority(form)
        .then(res => {
          if (res.code === 1) {
            this.editVisible = false;
            this.getList();
          }
        })
    },
    handleTrue() {
      let form = {...this.form};
      // 整理数据为后端要求格式
      form.pid = this.current.id;
      if (form.is_menu) {
        form.is_menu = 20
      } else {
        form.is_menu = 10
      }
      if (form.status) {
        form.status = 1
      } else {
        form.status = 0
      }
      form.access_type = Number(form.access_type);
      createAuthority(form)
        .then((res) => {
          if (res.code === 1) {
            this.handleCancle();
            this.getList();
          }
        })
    },
    handleAdd(val) {
      this.dialogFormVisible = true;
      if (val === 0) {
        this.type = 0;
      }
    },
    handleChange(e, current) {
      this.current = current;
      if (e === 0) {
        this.type = 1;
        this.dialogFormVisible = true;
      }
      if (e === 1) {
        // current.name = current.path;
        current.access_type = "" + current.access_type;
        this.currentEidt = { ...current };
        this.editVisible = true;
      }
      if (e === 2) {
        this.deleteVisible = true;
      }
    },
    handleDele() {
      deleteAuthority(this.current.id)
        .then(res => {
          if (res.code === 1) {
            this.handleCancle()
            this.getList();
          }
        })
    },
    getList() {
    this.loading = true;
    getList()
      .then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.listData = res.data;
          this.handleData(this.listData);
          this.listData.length && (this.current = this.listData[0]);
        }
      })
    },
  },
  created() {
    this.access_type = __access_type__;
    this.getList();
  },
};
</script>

<style scoped lang="less">
/deep/ .el-table__header-wrapper {
  table {
    .el-table_1_column_1 {
      text-align: center;
    }
  }
}
/deep/ .el-switch {
  line-height: 30px;
  height: 30px;
}
/deep/ .el-button--text {
  &:nth-child(3) {
    color: #FD563A;
  }
}
/deep/ .el-table_1_column_1 {
  // text-align: center;
}
</style>
